.image-marquee__scroller {
  display: flex;
  align-items: center; /* Align items vertically in the center */
}

.image-marquee__scroller__item {
  display: flex;
  align-items: center;
}

.image-marquee__scroller__item img {
  height: 50px; /* Set a consistent height */
  display: block; /* Ensure no extra space below images */
  margin-right: 10px;
}

.accordion {
  width: 100% !important;
  overflow: hidden !important;
  margin-top: 6px !important;
}

.accordion__intro {
  position: relative !important;
  padding: 14px 44px 14px 20px !important;
  cursor: pointer !important;
  background-color: #f2f2f2 !important;
  font-weight: 600 !important;
  font-size: 1.8rem !important;
}

@media (min-width: 768px) {
  .accordion__intro {
    font-size: 2.2rem !important;
  }
}

@media (min-width: 992px) {
  .accordion__intro {
    padding: 20px 40px 20px 20px;
    font-size: 3rem;
  }
}

.accordion__intro:after {
  content: " " !important;
  display: block !important;
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  top: 50% !important;
  right: 20px !important;
  margin-top: -10px !important;
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA0MDcuNDM3IDQwNy40MzcnPjxwYXRoIGQ9J00zODYuMjU4IDkxLjU2N2wtMTgyLjU0IDE4MS45NDVMMjEuMTc5IDkxLjU2NyAwIDExMi44MTUgMjAzLjcxOCAzMTUuODdsMjAzLjcxOS0yMDMuMDU1eicvPjwvc3ZnPg==);
  transform: rotate(180deg); /* Initial state pointing up */
  transition: transform 0.4s ease-out !important;
}

.accordion__intro.active:after {
  transform: rotate(0deg) !important; /* Rotate down when active */
}

.accordion__content {
  max-height: 0 !important;
  overflow: hidden !important;
  transition: max-height 0.4s ease-out, opacity 0.4s ease-out !important;
  color: #000 !important;
  opacity: 0 !important;
}

.accordion__content.active {
  max-height: 500px !important; /* Adjust as needed */
  opacity: 1 !important;
  padding: 20px !important;
}

.accordion__text {
  margin-bottom: 50px !important;
}
.facebook-icon path {
  fill: #ffffff; /* White color */
}
