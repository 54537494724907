.form-container {
  margin: 0 auto;
}

input[type="email"],
input[type="text"] {
  width: 100% !important;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input::placeholder {
  color: #999;
  opacity: 1;
  text-align: left !important; /* Ensure placeholder text is aligned to the left */
  right: 120px;
}

button[type="button"] {
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

form.js-hero-multistep .form-step {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
form.js-hero-multistep .form-step.active {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
form.js-hero-multistep .form-step.prev-field {
  opacity: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
form.js-hero-multistep
  .form-step.error
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="range"]
  ) {
  color: #ed1c24 !important;
}
form.js-hero-multistep
  .form-step.error
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="range"]
  )::-webkit-input-placeholder {
  color: #ed1c24 !important;
}
form.js-hero-multistep
  .form-step.error
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="range"]
  )::-moz-placeholder {
  color: #ed1c24 !important;
}
form.js-hero-multistep
  .form-step.error
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="range"]
  ):-ms-input-placeholder {
  color: #ed1c24 !important;
}
form.js-hero-multistep
  .form-step.error
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="range"]
  )::-ms-input-placeholder {
  color: #ed1c24 !important;
}
form.js-hero-multistep
  .form-step.error
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="range"]
  )::placeholder {
  color: #ed1c24 !important;
}
form.js-hero-multistep .form-step .email-status.loading,
form.js-hero-multistep .form-step .email-status.valid {
  top: 18px;
  right: 120px;
}
@media (min-width: 768px) {
  form.js-hero-multistep .form-step .email-status.loading,
  form.js-hero-multistep .form-step .email-status.valid {
    right: 150px;
  }
}
@media (min-width: 992px) {
  form.js-hero-multistep .form-step .email-status.loading,
  form.js-hero-multistep .form-step .email-status.valid {
    top: 24px;
  }
}
@media (min-width: 1600px) {
  form.js-hero-multistep .form-step .email-status.loading,
  form.js-hero-multistep .form-step .email-status.valid {
    right: 170px;
  }
}
form.js-hero-multistep
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="range"]
  ) {
  font-size: 1.4rem;
  border: none !important;
  width: 100%;
  background: #e7e7e7;
  color: #3d3d3d;
  height: 100%;
  padding: 0 0 0 30px;
}
form.js-hero-multistep
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="range"]
  )::-webkit-input-placeholder {
  color: #3d3d3d !important;
  opacity: 1 !important;
}
form.js-hero-multistep
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="range"]
  )::-moz-placeholder {
  color: #3d3d3d !important;
  opacity: 1 !important;
}
form.js-hero-multistep
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="range"]
  ):-ms-input-placeholder {
  color: #3d3d3d !important;
  opacity: 1 !important;
}
form.js-hero-multistep
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="range"]
  )::-ms-input-placeholder {
  color: #3d3d3d !important;
  opacity: 1 !important;
}
form.js-hero-multistep
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
    [type="range"]
  )::placeholder {
  color: #3d3d3d !important;
  opacity: 1 !important;
}
@media (min-width: 768px) {
  form.js-hero-multistep
    input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
      [type="range"]
    ) {
    padding-right: 140px;
    font-size: 1.6rem;
    padding-left: 70px;
  }
}
@media (min-width: 992px) {
  form.js-hero-multistep
    input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(
      [type="range"]
    ) {
    font-size: 1.8rem;
  }
}

textarea.long-textarea {
  height: 150px; /* Adjust height as needed */
}

/* form.js-hero-multistep.textarea-active {
  height: auto; /* Allow height to adjust for the textarea */

.form-step.textarea-step {
  min-height: 200px; /* Adjust height as needed to fit the textarea */
}

.form-step {
  margin-bottom: 20px;
}

form.js-hero-multistep.textarea-active {
  height: 300px !important; /* Height when textarea is active */
}
form.js-hero-multistep.textarea {
  height: 300px !important; /* Height when textarea is active */
}
@media (min-width: 992px) {
  form.js-hero-multistep.textarea {
    height: 300px !important; /* Height when textarea is active */
  }
  form.js-hero-multistep.textarea-active {
    height: 300px !important; /* Height when textarea is active */
  }
}

.modals {
  position: fixed; /* Make the modal fixed to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above all other content */
}

.modals-content {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 90%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modals-content h2 {
  color: #000 !important;
  font-size: 2em;
  margin-bottom: 10px;
}

.modals-content p {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #000 !important;
}

.modals-content button {
  background-color: #325345;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.modals-content button:hover {
  background-color: #2b4440;
}
