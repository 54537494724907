blockquote {
  display: none;
  padding: 20px;

  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0;
}

blockquote.active {
  display: block;
}

.carousel {
  position: relative;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #6c63ff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50%;
  user-select: none;
}

.carousel-control.prev {
  left: -40px;
  background: #325345;
}

.carousel-control.next {
  right: -40px;
  background: #325345;
}
/* Adjustments for mobile screens */
@media (max-width: 768px) {
  .carousel-control {
    padding: 8px;
    font-size: 16px;
  }

  .carousel-control.prev {
    left: 10px;
  }

  .carousel-control.next {
    right: 10px;
  }

  .carousel-inner {
    padding: 0 20px;
  }

  blockquote {
    padding: 15px;
    font-size: 14px;
  }
}

/* Add this CSS */
@media (max-width: 576px) {
  .love {
    margin-left: 15px; /* Adjust the value as needed */
  }
}
